import React, { useState, useEffect } from 'react';
import styles from '../styles/scss/components/newHeroHeader.module.scss';

const NewHeroHeader = ({ props }) => {
    

    const desktopVideo = 'https://files.wd40.com/video/WD40_DesktopScreenHomeVideo.mp4'
    const mobileVideo ='https://files.wd40.com/video/WD40_MobileScreenHomeVideo.mp4'

    const [videoSrc, setVideoSrc] = useState(desktopVideo);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setVideoSrc(desktopVideo);
            } else {
                setVideoSrc(mobileVideo);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [videoSrc]);

    return (
        <div className={styles.heroContainer} {...props}>
            <video
                key={videoSrc}
                autoPlay
                loop
                muted
                className={styles.heroVideo}
            >
                <source
                    src={videoSrc}
                    type="video/mp4"
                />
            </video>
            {/* <div className={styles.title}>
                <p>NEXT-LEVEL PERFORMANCE FOR THE <span>NEXT-LEVEL PRO</span></p>
            </div> */}
        </div>
    );
};

export default NewHeroHeader;
